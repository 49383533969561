// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import user from "./user";
import corps from "./corporations/index";
import buildings from "./buildings";
import fall from "./fall";
import alert from "./alert";
import sequences from "./sequences";
import visitors from "./visitors";
import users from "./users";
import residents from "./residents";
import visits from "./visits";
import robots from "./robots";
import rooms from "./rooms";
import calendars from "./calendars";
import slots from "./slots";

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  user,
  corps,
  buildings,
  sequences,
  users,
  visitors,
  residents,
  visits,
  robots,
  rooms,
  calendars,
  slots,
  alert,
  fall
});

export default rootReducer;
