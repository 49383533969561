const initialState = {
  access_token: localStorage?.getItem("accessToken")
    ? localStorage?.getItem("accessToken").split(" ")[1]
    : null,
  token_type: "Bearer",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.auth = JSON.stringify(action.payload);
      return action.payload;
    case "LOGOUT":
      return {};
    default:
      return state;
  }
};

export default authReducer;
